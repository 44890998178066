<template>
    <ShipDialog
        showTab="QD"
        width="500px"
        size="small"
        :visible.sync="visibleSync"
        title="青岛场站修改"
    >
        <span style="margin-right: 10px">场站修改选择:</span>
        <el-select placeholder="请选择场站" v-model="content" filterable style="width:320px">
            <el-option
                v-for="item in webNameList"
                :key="item"
                :label="luhaitongFilter(item)"
                :value="item"
            ></el-option>
        </el-select>
        <DialogFooter
            @confirm="handleTuConfirm"
            @cancel="handleCancel"
            :loading="loading"
            showTab="QD"
            top="30"
        ></DialogFooter>
    </ShipDialog>
</template>
<script>
import { updateWebname } from "@/api/qingdaoLogistics";
import { luhaitongFilter } from "../logistics";
import ShipDialog from "@/components/layout/ShipDialog.vue";
import DialogFooter from "@/components/layout/DialogFooter.vue";
export default {
    name: "QDWebNameDialog",
    data() {
        return {
            visibleSync: false,
            content: "",
            id: 0,
            loading: false,
            webNameList: [],
            oldWebName: "",
            luhaitongFilter,
        };
    },
    components: {
        ShipDialog,
        DialogFooter,
    },
    methods: {
        init(item, selectOptions) {
            this.oldWebName = item.webname || "";
            this.content = item.webname || "";
            this.visibleSync = true;
            this.id = item.id;
            this.webNameList = selectOptions;
        },
        handleCancel() {
            this.visibleSync = false;
        },
        handleTuConfirm() {
            if (this.oldWebName === this.content) {
                this.visibleSync = false;
                return;
            }
            const param = {
                webName: this.content,
                id: this.id,
            };
            this.loading = true;
            updateWebname(param)
                .then((res) => {
                    this.$message({
                        message: "修改场站成功",
                        type: "success",
                    });
                    this.$emit("confirm", this.content);
                    this.visibleSync = false;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>
