<template>
    <!-- 设置微信推送弹窗 -->
    <el-dialog
        title="日志"
        :visible.sync="dialogTableVisible"
        center
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        class="journal_dialog"
        width="1240px"
    >
        <div>
            <div class="search_input_div">
                <el-select v-model="operdesc" clearable placeholder="请选择">
                    <el-option
                        v-for="item in operdescopt"
                        :key="item"
                        :label="item"
                        :value="item"
                    ></el-option>
                </el-select>
                <el-date-picker
                    v-model="searchtime"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions"
                ></el-date-picker>
                <el-button
                    slot="append"
                    icon="el-icon-search"
                    @click="getJournal"
                ></el-button>
            </div>
            <div class="table_div">
                <el-table
                    border
                    stripe
                    :data="journalDate"
                    style="width: 1181px"
                    row-key="id"
                    :cell-style="{
                        textAlign: 'center',
                        alignItems: 'baseline',
                        padding: '10px 0',
                    }"
                    :header-cell-style="{
                        background: '#eef1f6',
                        color: '#606266',
                        textAlign: 'center',
                        padding: '12px 0',
                    }"
                    :tree-props="{ children: 'children' }"
                >
                    <el-table-column
                        prop="index"
                        label="序号"
                        width="80"
                    ></el-table-column>
                    <el-table-column
                        prop="operdesc"
                        label="操作类型"
                        width="200"
                    ></el-table-column>
                    <el-table-column
                        prop="createTime"
                        label="操作时间"
                        width="200"
                    ></el-table-column>
                    <el-table-column
                        prop="operation"
                        label="具体操作"
                        width="520px"
                    ></el-table-column>
                    <el-table-column
                        prop="result"
                        label="操作结果"
                        width="180px"
                    ></el-table-column>
                </el-table>
            </div>
            <div class="pagination_div">
                <el-pagination
                    background
                    :hide-on-single-page="pageTotal < 10"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    layout="sizes,prev, pager, next"
                    :total="pageTotal"
                    :page-sizes="[10, 20, 30, 40, 50]"
                    :page-size="pageSize"
                    :current-page.sync="pagePosition"
                ></el-pagination>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import { queryIntroduction, queryLog } from "@/api/login";
export default {
    data() {
        return {
            test: "测试信息",
            dialogTableVisible: false,
            blno: "",
            pageTotal: 0,
            pageSize: 10,
            pagePosition: 1,
            startRow: 1,
            operdescopt: [], //全部操作
            operdesc: "", //选择的操作
            searchtime: "", //搜索框时间
            journalDate: [],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "今天",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近一周",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 7,
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近一个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 30,
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                    {
                        text: "最近三个月",
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(
                                start.getTime() - 3600 * 1000 * 24 * 90,
                            );
                            picker.$emit("pick", [start, end]);
                        },
                    },
                ],
            },
        };
    },
    components: {},
    mounted() {},
    methods: {
        async init(blno) {
            this.blno = blno;
            this.pageTotal = 0;
            this.pageSize = 10;
            this.pagePosition = 1;
            this.startRow = 1;
            this.operdesc = "";
            this.searchtime = "";
            await this.getOperdesc();
            await this.getJournal();
            this.dialogTableVisible = true;
        },
        //修改分页的pagesize
        handleSizeChange(val) {
            this.pageSize = val;
            this.pagePosition = 1;
            this.getJournal();
        },
        //分页
        handleCurrentChange(val) {
            this.pagePosition = val;
            this.getJournal();
        },
        //获取操作说明"
        async getOperdesc() {
            var that = this;
            queryIntroduction()
                .then(function (response) {
                    var data = response.data;
                    if (data.status) {
                        that.operdescopt = data.data;
                    }
                })
                .catch((err) => {
                    that.$message.error(err);
                });
        },
        async getJournal() {
            var that = this;
            var data = {};
            data.userid = this.$store.state.user.userId;
            data.blno = this.blno;
            data.current = this.pagePosition;
            data.size = this.pageSize;
            data.operdesc = this.operdesc;
            data.start = this.searchtime
                ? this.msToDate(this.searchtime[0]).withoutTime
                : this.searchtime;
            data.end = this.searchtime
                ? this.msToDate(this.searchtime[1]).withoutTime
                : this.searchtime;
            console.log(data);
            await // this.$axios
            //   .post('operlog/queryOperlog', data)
            queryLog(data)
                .then(function (response) {
                    console.log(response);
                    var data = response.data;
                    if (data.status) {
                        that.pageTotal = data.data.total;
                        that.pagePosition = data.data.pageNum;
                        that.startRow = data.data.startRow;
                        that.journalDeal(data.data.list);
                    } else {
                        that.$message.error(data.msg);
                    }
                })
                .catch((err) => {
                    that.$message.error(err);
                });
        },
        journalDeal(list) {
            this.journalDate = [];
            console.log(list);
            list.forEach((item) => {
                let data = {};
                data.index = this.tableIndex(this.journalDate.length);
                data.createTime = item.createTime;
                data.operdesc = item.operdesc;
                data.result = item.outparameter;
                if (item.operdesc == "上传模板数据") {
                    data.operation = item.outparameter;
                } else if (
                    item.operdesc == "批量删除箱单" ||
                    item.operdesc == "历史撤回"
                ) {
                    if (item.operdesc == "批量删除箱单") {
                        data.operation = "将" + item.blno + "删除";
                    } else {
                        data.operation = "将" + item.blno + "还原";
                    }
                } else if (
                    item.operdesc == "批量置顶箱单" ||
                    item.operdesc == "批量取消置顶箱单"
                ) {
                    if (item.operdesc == "批量置顶箱单") {
                        data.operation = "将" + item.blno + "置顶";
                    } else {
                        data.operation = "将" + item.blno + "取消置顶";
                    }
                } else if (item.operdesc == "订阅箱单") {
                    let operateList = this.getString2Json(item.inparameter);
                    if (item.outparameter == "修改成功") {
                        if (operateList.remark) {
                            data.operation =
                                "将" +
                                operateList.blno +
                                "的备注修改为：" +
                                operateList.remark;
                        } else if (operateList.remark == "") {
                            data.operation =
                                "将" + operateList.blno + "的备注修改为：空";
                        } else if (
                            operateList.vesselename &&
                            operateList.voyage &&
                            !operateList.preentry
                        ) {
                            data.operation =
                                "将" +
                                operateList.blno +
                                "的船名/航次修改为：" +
                                operateList.vesselename +
                                "/" +
                                operateList.voyage;
                        } else if (operateList.preentry) {
                            data.operation =
                                "将车牌号修改为：" + operateList.preentry;
                        }
                    } else if (item.outparameter == "新增成功") {
                        data.operation =
                            "新增订阅提单号：" +
                            operateList.blno +
                            "，" +
                            "船名/航次：" +
                            operateList.vesselename +
                            "/" +
                            operateList.voyage;
                    }
                }
                this.journalDate.push(data);
            });
        },
        closeDialog() {
            this.dialogTableVisible = false;
        },

        getString2Json(data) {
            var json = eval("[" + data + "]")[0];
            return json;
        },
        getString2JsonArr(data) {
            var json = eval(data);
            return json;
        },
        //中国标准时间转化
        msToDate(msec) {
            let datetime = new Date(msec);
            let year = datetime.getFullYear();
            let month = datetime.getMonth();
            let date = datetime.getDate();
            let hour = datetime.getHours();
            let minute = datetime.getMinutes();
            let second = datetime.getSeconds();
            let result1 =
                year +
                "-" +
                (month + 1 >= 10 ? month + 1 : "0" + (month + 1)) +
                "-" +
                (date + 1 < 10 ? "0" + date : date) +
                " " +
                (hour + 1 < 10 ? "0" + hour : hour) +
                ":" +
                (minute + 1 < 10 ? "0" + minute : minute) +
                ":" +
                (second + 1 < 10 ? "0" + second : second);

            let result2 =
                year +
                "-" +
                (month + 1 >= 10 ? month + 1 : "0" + (month + 1)) +
                "-" +
                (date + 1 < 10 ? "0" + date : date);

            let result = {
                hasTime: result1,
                withoutTime: result2,
            };
            return result;
        },
        //表格序号根据分页连续显示
        tableIndex(index) {
            return this.startRow + index;
        },
    },
};
</script>
<style lang="stylus" scoped>
.journal_dialog {
  &.el-dialog__wrapper{
    width:100%;
  }
}
.search_input_div {
  display: flex;
  justify-content: center;
}

.pagination_div {
  margin: 2rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.table_div {
  // min-height: 52rem;
  margin-top: 20px;
}
</style>
