<!-- 订阅提单号为主提单号，列出全部拼票让用户选择订阅(目前只有宁波) -->
<template>
    <ShipDialog
        @close="close"
        title="拼票订阅"
        showTab="NB"
        :visible.sync="visible"
        class="child-blno-dialog"
    >
        <el-alert
            type="success"
            title="检测到当前提单号为拼票，以下为所有拼票的提单号，请您自主选择需要订阅的提单号。"
        ></el-alert>
        <el-table
            :data="blnoList"
            border
            :header-cell-style="{
                background: '#eef1f6',
                color: '#606266',
            }"
            style="margin-top: 12px"
            stripe
            @selection-change="handleSelectionChange"
        >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column
                v-for="item in tableColumns"
                :label="item.label"
                :key="item.prop"
                :prop="item.prop"
                :width="item.width"
            >
                <template slot-scope="scope">
                    <span v-if="item.prop === 'shipInfo'">
                        {{ `${vesselsName}/${voyage}` }}
                    </span>
                    <span v-else>{{ scope.row[item.prop] }}</span>
                </template>
            </el-table-column>
        </el-table>
     
            <DialogFooter
                :loading="confirmLoading"
                @confirm="handleSubmit"
                @cancel="close"
                confirmText="订阅"
                :top="35"
            ></DialogFooter>
      
    </ShipDialog>
</template>

<script>
import ShipDialog from "@/components/layout/ShipDialog.vue";
import DialogFooter from "@/components/layout/DialogFooter.vue";
const tableColumns = [
    {
        prop: "blno",
        label: "提单号",
        width: 200,
    },
    {
        prop: "shipInfo",
        label: "船名/航次",
        minWidth: 300,
    },
    {
        prop: "packageNum",
        label: "件数",
        width: 100,
    },
    {
        prop: "weight",
        label: "毛重",
        width: 100,
    },
    {
        prop: "measure",
        label: "体积",
        width: 100,
    },
];

export default {
    name: "ChildBlnoDialog",
    emits: ["confirm"],
    data() {
        return {
            tableColumns,
            blnoList: [],
            visible: false,
            selectedRows: [],
            confirmLoading: false,
            vesselsName: "",
            voyage: "",
        };
    },
    methods: {
        open({ blnos, vesselsName, voyage }) {
            this.blnoList = blnos.reverse();
            this.vesselsName = vesselsName;
            this.voyage = voyage;
            this.visible = true;
        },
        close() {
            this.confirmLoading = false;
            this.blnoList = [];
            this.visible = false;
        },
        handleSelectionChange(rows) {
            this.selectedRows = rows;
        },
        handleSubmit() {
            const blnoArr = this.selectedRows
                .map((item) => item.blno)
                .map((item) => {
                    return {
                        blno: item,
                        vesselsName: this.vesselsName,
                        voyage: this.voyage,
                    };
                });
            if (blnoArr.length) {
                this.confirmLoading = true;
                this.$emit("confirm", blnoArr, { fromChildDialog: true });
            } else {
                this.$message.warning("请选择需要订阅的提单号！");
            }
        },
    },
    components: {
        ShipDialog,
        DialogFooter,
    },
};
</script>

<style lang="stylus">
.child-blno-dialog{
    .el-dialog__body {
        padding-top: 0;
        padding-bottom: 12px;
    }
    .el-table__body .el-table__cell:first-child .cell{
        padding-left: 0;
    }
}
</style>
