<template>
    <ShipDialog
        :visible.sync="visibleSync"
        :title="title"
        width="500px"
        size="small"
        :showTab="showTab"
    >
        <el-input v-model="content" placeholder="请输入舱单主单号"></el-input>
        <DialogFooter
            :showTab="showTab"
            @confirm="handleTuConfirm"
            @cancel="handleCancel"
        ></DialogFooter>
    </ShipDialog>
</template>
<script>
import { logisticsRequest } from "../logistics";
import { getCompanyId, getRole } from "@/utils/auth";
import ShipDialog from "@/components/layout/ShipDialog";
import DialogFooter from "@/components/layout/DialogFooter.vue";
export default {
    name: "QDSplitBlnoMainDialog",
    props: {
        showTab: {
            type: String,
            default: "NB",
        },
    },
    components: {
        ShipDialog,
        DialogFooter,
    },
    data() {
        return {
            visibleSync: false,
            content: "",
            ids: [],
            loading: false,
        };
    },
    computed: {
        title() {
            return "拼箱舱单主单号修改";
        },
    },
    methods: {
        init(item) {
            const curItem = item[0];
            this.content = curItem.blnoMain || "";
            this.visibleSync = true;
            this.ids = item.map((item) => item.id);
        },
        handleCancel() {
            this.visibleSync = false;
        },
        handleTuConfirm() {
            const param = {
                blnoMain: this.content,
                ids: this.ids,
                role: getRole(),
                companyId: getCompanyId(),
            };
            this.loading = true;
            const reqFunc = logisticsRequest({
                type: 8,
                tab: this.showTab,
            });
            reqFunc(param)
                .then((res) => {
                    this.$message({
                        message: "修改成功",
                        type: "success",
                    });
                    this.$emit("confirm", this.content);
                    this.visibleSync = false;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>
