<template>
    <el-tooltip :placement="placement">
        <i class="el-icon-warning"></i>
        <div slot="content">
            <slot>
                <div>{{ content }}</div>
            </slot>
        </div>
    </el-tooltip>
</template>
<script>
export default {
    props: {
        content: {
            type: String,
            default: "",
        },
        placement: {
            type: String,
            default: "bottom",
        },
    },
};
</script>
