<template>
    <div>
        <ShipDialog :visible.sync="visibleSync" :title="`${showTabTitle}提单`" :showTab='showTab' width="665px">
            <el-alert
                :title="tipText"
                :closable="false"
                type="error"
            ></el-alert>
            <el-input
                type="textarea"
                :autosize="{ minRows: 2, maxRows: 30 }"
                placeholder="请输入提单号"
                v-model="textarea"
            ></el-input>
            <DialogFooter @confirm="handleTuConfirm" @cancel="handleCancel" :showTab='showTab'></DialogFooter>
        </ShipDialog>
    </div>
</template>
<script>
import { getNameByCode } from "@/utils";
import ShipDialog from "@/components/layout/ShipDialog.vue";
import DialogFooter from "@/components/layout/DialogFooter.vue";

export default {
    components: {
        ShipDialog,
        DialogFooter
    },
    props: ["showTab"],
    data() {
        return {
            visibleSync: false,
            textarea: "",
            tipText:
                "请粘贴所有你想订阅的分票提单号，每一行为一条提单号,主提单号需要在外面提单号的格子中录入",
            confirmVisibleSync: false,
        };
    },
    computed: {
        showTabTitle() {
            // const namePlaceEnum = {
            //     SH: "上海",
            //     NB: "宁波",
            //     QD: "青岛",
            // };
            // const textEnum = {
            //     SH: "请粘贴所有你想订阅的分票提单号，每一行为一条提单号,主提单号需要在外面提单号的格子中录入",
            //     QD: "请在此处输入拼箱主提单号，提单号需要在外面提单号的格子中录入",
            // };
            // this.tipText = textEnum[this.showTab];
            return getNameByCode(this.showTab);
        },
    },
    methods: {
        open() {
            this.visibleSync = true;
            this.textarea = "";
        },
        handleCancel() {
            this.visibleSync = false;
        },
        // 真实提交
        handleTuConfirm() {
            if (!this.textarea.trim()) {
                this.$message.error("请输入提单号");
                return;
            }
            this.$emit("confirm", this.textarea.trim());
            this.handleCancel();
        },
    },
};
</script>
