<template>
    <el-popover
        trigger="click"
        width="900"
        @hide="takeoutTableData = null"
        @show="handleTakeoutPopShow(rowData)"
    >
        <el-table
            v-loading="takeoutTableData === null"
            :data="takeoutTableData"
            style="width: 100%"
        >
            <template v-for="takeoutItem in takeoutTable">
                <el-table-column
                    v-if="takeoutItem.show"
                    show-overflow-tooltip
                    :prop="takeoutItem.prop"
                    :sortable="takeoutItem.sortable"
                    :label="takeoutItem.label"
                    :width="takeoutItem.width"
                    :key="takeoutItem.prop"
                    :resizable="takeoutItem.resizable"
                ></el-table-column>
            </template>
        </el-table>
        <span slot="reference" class="takeout_position">
            <span class="table_link_text_bold">
                {{ `${rowData.emptyOutCount || 0}箱` }}
            </span>
            <span
                v-if="rowData.emptyOutCount > 0"
                class="car-search"
                @click="goTruckMap(rowData.id)"
            ></span>
            <div v-if="!rowData.emptyOutCount > 0" class="padcar"></div>
        </span>
    </el-popover>
</template>

<script>
import {
    queryTakeout,
} from "@/api/tracking";
export default {
    name: 'EmptyTakeout',
    props: {
        rowData: {
            type: Object,
            default: () => ({})
        },
    },
    data() { 
        return {
            takeoutTableData: null, // 提空箱table展示数据
        }
    },
    methods: {
        // 提空箱popover出发时获取数据
        handleTakeoutPopShow({ blno }) {
            queryTakeout(blno)
                .then((res) => {
                    const { status, data, msg } = res.data;
                    if (status) {
                        this.takeoutTableData = data;
                    } else {
                        this.takeoutTableData = [];
                        this.$message.error(msg);
                    }
                })
                .catch(() => {
                    // 已经全局抛错
                    this.takeoutTableData = [];
                });
        },
        goTruckMap(id) {
            const newpage = this.$router.resolve({
                path: "/truckmap",
                query: {
                    id,
                },
            });
            window.open(newpage.href, "_blank");
        },
    }
}
</script>

<style lang="stylus" scoped>
.takeout_position {
    display: flex;
    justify-content: center;
    align-items: center;
}
.table_link_text_bold {
  color: #409eff;
  font-weight:bold;
  font-size:14px;
  cursor: pointer;
  user-select: none;
  &:hover{
    opacity: 0.8;
  }
}
.car-search {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  cursor: pointer;
  background-image: url('../../../assets/img/position.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  user-select: none;
  transition: all 250ms ease-out;
  &:hover{
    transform: scale(1.1);
  }
}
.padcar {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}
</style>
