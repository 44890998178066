<template>
    <!-- 设置微信推送弹窗 -->
    <SettingLayout ref="settingRef" v-if="dialogTableVisible" title="物流-微信推送设置">
        <div class="checkbox_group_div">
            <div class="checkbox_div">
                <div class="flex_div">
                    <p class="info_title">同一组拼票只推送一票：</p>
                    <el-checkbox v-model="isPushSon"></el-checkbox>
                </div>
                <div class="flex_div">
                    <p class="info_title">船舶推送：</p>
                    <el-checkbox
                        :indeterminate="isIndeterminateBoat"
                        v-model="checkAllboat"
                        @change="handleCheckAllChangeBoat"
                    >
                        全选
                    </el-checkbox>
                </div>
                <el-checkbox-group
                    v-model="checkedBoatlist"
                    @change="handleCheckedBoatChange"
                >
                    <el-checkbox
                        v-for="(city, cindex) in boatlist"
                        :label="city"
                        :key="city"
                        :disabled="!isNbSystem && cindex < 3"
                    >
                        {{ city }}
                    </el-checkbox>
                </el-checkbox-group>
            </div>
            <div class="checkbox_div">
                <div class="flex_div">
                    <p class="info_title">箱货推送：</p>
                    <el-checkbox
                        :indeterminate="isIndeterminateBox"
                        v-model="checkAllbox"
                        @change="handleCheckAllChangeBox"
                    >
                        全选
                    </el-checkbox>
                </div>
                <el-checkbox-group
                    v-model="checkedBoxlist"
                    @change="handleCheckedBoxChange"
                >
                    <el-checkbox
                        v-for="city in boxlist"
                        :label="city"
                        :key="city"
                    >
                        {{ city }}
                    </el-checkbox>
                </el-checkbox-group>
            </div>
            <div class="checkbox_div">
                <div
                    class="flex_div"
                    v-if="['NB', 'SH'].includes(this.showTab)"
                >
                    <p class="info_title2">
                        异常推送：（为保障业务，异常推送默认开启，无法关闭。）
                    </p>
                </div>
                <el-checkbox-group
                    v-if="['NB', 'SH'].includes(this.showTab)"
                    v-model="checkedErrorlist"
                    @change="handleCheckedErrorChange"
                >
                    <el-checkbox
                        v-for="city in errorlist"
                        :label="city"
                        :key="city"
                        disabled
                    >
                        {{ city }}
                    </el-checkbox>
                </el-checkbox-group>
                <div class="flex_div">
                    <p class="info_title">临近截关时间设置：</p>
                    <el-select v-model="portCloseH" size="mini">
                        <el-option
                            v-for="item in timelist"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </div>
                <div class="flex_div">
                    <p class="info_title">临近截港时间设置：</p>
                    <el-select v-model="ctnApplyEndH" size="mini">
                        <el-option
                            v-for="item in timelist"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        ></el-option>
                    </el-select>
                </div>
            </div>
        </div>
        <DialogFooter @confirm="updateSet" @cancel="closeDialog" top="20" />
    </SettingLayout>
</template>
<script>
import { pushSetting, pushSettingUpdate } from "@/api/push";
import { apiCallback, debounce } from "../../../utils";
import DialogFooter from "@/components/layout/DialogFooter.vue";
import SettingLayout from "@/components/layout/SettingLayout.vue";
export default {
    props: ["showTab"],
    data() {
        return {
            test: "测试信息",
            dialogTableVisible: false,
            isPushSon: false,
            checkAllboat: true,
            checkAllbox: true,
            checkAllError: false,
            //箱货推送
            boxlist: [
                "海关预配已接受推送",
                "已进港推送",
                "海关已放行推送",
                "码头已放行推送",
            ],
            // 选中箱货推送
            checkedBoxlist: [],
            checkedBoatlist: [],
            //异常推送
            errorlist: ["海关查验推送", "码放异常推送"],
            checkedErrorlist: ["海关查验推送", "码放异常推送"],
            //indeterminate 属性用以表示 checkbox 的不确定状态，一般用于实现全选的效果
            isIndeterminateOne: false,
            isIndeterminateBoat: false,
            isIndeterminateBox: false,
            isIndeterminateError: true,
            portCloseH: "", //截关时间
            ctnApplyEndH: "", //截港时间
            timelist: [
                { value: 12, label: "12小时" },
                { value: 18, label: "18小时" },
                { value: 24, label: "24小时" },
                { value: 30, label: "30小时" },
                { value: 36, label: "36小时" },
            ],
            tempcheckeddata: {},
            logoImg: require("@/assets/img/login/logo.png"),
            popupRight: require("@/assets/img/login/pupup_right.png"),
            settingImg: require("@/assets/img/setting/setting.png"),
        };
    },
    components: {
        DialogFooter,
        SettingLayout
    },

    computed: {
        isNbSystem() {
            return this.showTab === "NB";
        },
        //船舶推送
        boatlist() {
            return [
                "进箱开始/截止时间推送",
                "截单/截关时间及变更推送",
                "预计抵离港时间及变更推送",
                "已装船推送",
                "已抵港/离泊推送",
            ];
            // return this.isNbSystem
            //     ? [
            //           "进箱开始/截止时间推送",
            //           "截单/截关时间及变更推送",
            //           "预计抵离港时间及变更推送",
            //           "已装船推送",
            //           "已抵港/离泊推送",
            //       ]
            //     : ["已装船推送", "离泊推送"];
        },
    },
    methods: {
        init() {
            this.dialogTableVisible = true;
            this.$nextTick(()=>{
                this.$refs.settingRef.init();
            })
            this.checkedBoatlist = [];
            this.checkedBoxlist = [];
            this.getSetting();
        },
        handleCheckAllChangeBoat(val) {
            this.checkedBoatlist = val ? this.boatlist : [];
            this.isIndeterminateBoat = false;
        },
        handleCheckedBoatChange(value) {
            let checkedCount = value.length;
            this.checkAllboat = checkedCount === this.boatlist.length;
            this.isIndeterminateBoat =
                checkedCount > 0 && checkedCount < this.boatlist.length;
        },
        handleCheckAllChangeBox(val) {
            this.checkedBoxlist = val ? this.boxlist : [];
            this.isIndeterminateBox = false;
        },
        handleCheckedBoxChange(value) {
            let checkedCount = value.length;
            this.checkAllbox = checkedCount === this.boxlist.length;
            this.isIndeterminateBox =
                checkedCount > 0 && checkedCount < this.boxlist.length;
        },
        handleCheckAllChangeError(val) {
            this.checkedErrorlist = val ? this.errorlist : [];
            this.isIndeterminateError = false;
        },
        handleCheckedErrorChange(value) {
            let checkedCount = value.length;
            this.checkAllError = checkedCount === this.errorlist.length;
            this.isIndeterminateError =
                checkedCount > 0 && checkedCount < this.errorlist.length;
        },
        updateSet() {
            let data = {
                ...this.tempcheckeddata,
            };
            data.portCloseH = this.portCloseH;
            data.ctnApplyEndH = this.ctnApplyEndH;
            //箱货
            data.isPre = this.checkedBoxlist.includes("海关预配已接受推送")
                ? 1
                : 0;
            data.isInGate = this.checkedBoxlist.includes("已进港推送") ? 1 : 0;
            data.isCustpass = this.checkedBoxlist.includes("海关已放行推送")
                ? 1
                : 0;
            data.isNpp = this.checkedBoxlist.includes("码头已放行推送") ? 1 : 0;
            //船舶
            data.isCtnApply = this.checkedBoatlist.includes(
                "进箱开始/截止时间推送",
            )
                ? 1
                : 0;
            data.isClose = this.checkedBoatlist.includes(
                "截单/截关时间及变更推送",
            )
                ? 1
                : 0;
            data.isEta = this.checkedBoatlist.includes(
                "预计抵离港时间及变更推送",
            )
                ? 1
                : 0;
            data.isLoad = this.checkedBoatlist.includes("已装船推送") ? 1 : 0;
            data.isArrival = this.checkedBoatlist.includes("已抵港/离泊推送")
                ? 1
                : 0;
            data.isPushSon = this.isPushSon ? 0 : 1;

            if (this.isObjectChanged(data, this.tempcheckeddata)) {
                this.closeDialog();
                return;
            } else {
                var that = this;
                pushSettingUpdate(data)
                    .then(async (response) => {
                        that.$store.commit(
                            "ship/editPorttime",
                            that.portCloseH,
                        );
                        that.$store.commit(
                            "ship/editCtnapplyend",
                            that.ctnApplyEndH,
                        );
                        that.$message.success("推送设置修改成功！");
                        that.closeDialog();
                    })
                    .catch((err) => {
                        console.error(err);
                        that.closeDialog();
                    });
            }
        },
        getSetting() {
            var that = this;
            pushSetting()
                .then((response) => {
                    const data = apiCallback(response);
                    if (data.isPushSon === 1) {
                        that.isPushSon = false;
                    } else if (data.isPushSon === 0) {
                        that.isPushSon = true;
                    }
                    that.portCloseH = data.portCloseH;
                    that.ctnApplyEndH = data.ctnApplyEndH;
                    that.$store.commit("ship/editPorttime", data.portCloseH);
                    that.$store.commit(
                        "ship/editCtnapplyend",
                        data.ctnApplyEndH,
                    );
                    //箱货推送
                    var isPre = data.isPre; //海关预配已接受推送
                    var isInGate = data.isInGate; //已进港推送
                    var isCustpass = data.isCustpass; //海关已放行推送
                    var isNpp = data.isNpp; //码头已放行推送
                    if (isPre === 1) {
                        that.checkedBoxlist.push(that.boxlist[0]);
                    }
                    if (isInGate === 1) {
                        that.checkedBoxlist.push(that.boxlist[1]);
                    }
                    if (isCustpass === 1) {
                        that.checkedBoxlist.push(that.boxlist[2]);
                    }
                    if (isNpp === 1) {
                        that.checkedBoxlist.push(that.boxlist[3]);
                    }
                    //船舶推送
                    var isCtnApply = data.isCtnApply; //进箱开始/截止时间推送
                    var isClose = data.isClose; //截单/截关时间及变更推送
                    var isEta = data.isEta; //预计抵离港时间及变更推送
                    var isLoad = data.isLoad; //已装船推送
                    var isArrival = data.isArrival; //已抵港/离泊推送
                    if (isCtnApply === 1) {
                        that.checkedBoatlist.push(that.boatlist[0]);
                    }
                    if (isClose === 1) {
                        that.checkedBoatlist.push(that.boatlist[1]);
                    }
                    if (isEta === 1) {
                        that.checkedBoatlist.push(that.boatlist[2]);
                    }
                    if (isLoad === 1) {
                        that.checkedBoatlist.push(that.boatlist[3]);
                    }
                    if (isArrival === 1) {
                        that.checkedBoatlist.push(that.boatlist[4]);
                    }
                    that.tempcheckeddata = {
                        ctnApplyEndH: data.ctnApplyEndH,
                        isArrival: data.isArrival,
                        isClose: data.isClose,
                        isCtnApply: data.isCtnApply,
                        isCustpass: data.isCustpass,
                        isEta: data.isEta,
                        isInGate: data.isInGate,
                        isLoad: data.isLoad,
                        isNpp: data.isNpp,
                        isPre: data.isPre,
                        isPushSon: data.isPushSon,
                        portCloseH: data.portCloseH,
                    };
                    that.handleCheckedBoatChange(that.checkedBoatlist);
                    that.handleCheckedBoxChange(that.checkedBoxlist);
                })
                .catch((err) => {
                    console.error(err);
                });
        },

        closeDialog() {
            this.dialogTableVisible = false;
        },
        isObjectChanged(source, comparison) {
            const _source = JSON.stringify(source);
            const _comparison = JSON.stringify({ ...source, ...comparison });
            return _source === _comparison;
        },
    },
};
</script>
<style lang="stylus" scoped>
.push_setting_dialog{

  .el-checkbox {
    // margin-right: 25px;
    margin-bottom: 1rem;
  }
}
.checkbox_group_div {
  display: flex;
  flex-direction: column;
  padding-bottom:20px;
  border-bottom:2px solid #ebeef5;
}

.checkbox_div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.flex_div {
  display: flex;
  flex-direction: row;
  align-items: center;

  .el-checkbox {
    margin-bottom: 0;
  }
}

.info_title {
  display: block;
  width: fit-content;
  height: 50px;
  line-height: 50px;
  font-weight: 700;
  font-size: 1.6rem;
  margin: 0;
}

.info_title:before {
  content: '';
  height: 2rem;
  margin-top: 12px;
  margin-right: 1rem;
  border-left: 2px solid #de5d40;
}

// 2345浏览器使用info_title会导致文字过长换行错位
.info_title2 {
  display: block;
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-weight: 700;
  font-size: 1.6rem;
  margin: 0;
}

.info_title2:before {
  content: '';
  height: 2rem;
  margin-top: 12px;
  margin-right: 1rem;
  border-left: 2px solid #de5d40;
}
</style>
