<template>
    <span class="box_position">
        <el-dropdown
            :hide-on-click="false"
            placement="bottom"
            @visible-change="handleVChange"
            trigger="click"
            class="el-dropdown-car"
        >
            <span class="table_link_text_bold">添加车号</span>
            <el-dropdown-menu slot="dropdown">
                <div class="subscribe-tooltip-wrap">
                    <div>
                        注意：订阅功能为<span>收费项目</span>
                    </div>
                    <div>
                        收费标准为：<span>1火币/车牌</span> 
                    </div>
                    <div>
                        (添加成功即收费，删除后不退费)
                    </div>
                </div>
                <div v-if="rowData[cellProp] != []" style="width: 250px">
                    <el-dropdown-item
                        class="el-dropdown-car__item"
                        v-for="(item, index) in rowData[cellProp]"
                        :key="index"
                        style="
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                        "
                    >
                        {{ `车号${Number(index) + 1}：${item}` }}

                        <p class="delete-btn" @click="deleteCarNo(rowData, item)">
                            删除
                        </p>
                    </el-dropdown-item>
                </div>
                <el-dropdown-item class="el-dropdown-car__item">
                    <i
                        v-if="!showInput"
                        class="el-icon-plus"
                        @click="showInput = true"
                        style="color: #409eff"
                    ></i>
                    <el-input
                        v-if="showInput"
                        v-model="newCarNum"
                        placeholder="示例:浙Bxxxxx"
                        style="width: 220px"
                        size="small"
                    >
                        <template slot="append">
                            <el-button
                                type="primary"
                                size="small"
                                :loading="addLoading"
                                @click="addNewCarnum(rowData)"
                            >
                                确定
                            </el-button>
                        </template>
                    </el-input>
                </el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <span
            v-if="rowData[cellProp].length > 0"
            class="car-search"
            @click="showCarPosition(rowData)"
        ></span>
    </span>
</template>

<script>
import { getToken } from "@/utils/auth";
import {
    updateRemarkAndTruck,
} from "@/api/boxQuery";
import { getCarTrajectory } from '@/api/truckSubscribe';

export default {
    name: 'TruckNoAdd',
    props: {
        rowData: {
            type: Object,
            default: () => ({})
        },
        cellProp: {
            type: String
        }
    },
    data() { 
        return {
            showInput: false,
            newCarNum: '',
            addLoading: false
        }
    },
    methods: {
        handleVChange(e) {
            if (!e) {
                this.showInput = false;
                this.newCarNum = "";
            }
        },
        deleteCarNo(obj, deteno) {
            if (getToken()) {
                var truckLicense = "";
                if (obj.boxPosition) {
                    obj.boxPosition.forEach((item) => {
                        if (item != deteno) {
                            truckLicense = truckLicense + item + ",";
                        }
                    });
                }
                truckLicense = truckLicense.substring(
                    0,
                    truckLicense.length - 1,
                );
                const params = {
                    id: obj.id,
                    truckLicense,
                };
                var that = this;
                updateRemarkAndTruck(params)
                    .then(function () {
                        that.showInput = false;
                        that.newCarNum = "";
                        that.$message.success("删除成功");
                        that.$emit('change');
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            } else {
                this.$emit('login')
            }
        },
        isLicenseNo(str) {
            var regExp =
                /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/;
            if (!regExp.test(str)) {
                return false;
            } else {
                // console.log("车牌号正确")
                return true;
            }
        },
        // 订阅集卡车
        subscribeTruck(obj) { 
            return new Promise((resolve) => { 
                const reqParam = {
                    blno: obj.blno,
                    truckLicense: this.newCarNum
                }
                if (this.$store.state.user.cid) { 
                    reqParam.companyId = this.$store.state.user.cid
                }
                getCarTrajectory(reqParam).then(res => { 
                    resolve(res)
                }).catch(() => { 
                    this.addLoading = false
                })
            })
        },
        //集卡定位添加新车
        addNewCarnum(obj) {
            if (getToken()) {
                var is_no = this.isLicenseNo(this.newCarNum);
                if (this.newCarNum != "" && is_no) {
                    this.addLoading = true;
                    var truckLicense = "";
                    if (obj.boxPosition) {
                        obj.boxPosition.forEach((item) => {
                            truckLicense = truckLicense + item + ",";
                        });
                    }
                    var num = obj.boxPosition.findIndex(
                        (item) => item == this.newCarNum,
                    );
                    if (num == -1) {
                        truckLicense += this.newCarNum;
                    } else {
                        this.$message.warning("请勿重复添加车牌号");
                        return;
                    }

                    var that = this;
                    const params = {
                        id: obj.id,
                        truckLicense: truckLicense,
                    };
                    this.subscribeTruck(obj).then(res => { 
                        updateRemarkAndTruck(params)
                            .then(function () {
                                that.showInput = false;
                                that.newCarNum = "";
                                that.$message.success("添加成功");
                                that.$emit('change')
                            }).finally(() => { 
                                that.addLoading = false;
                            })
                    })
                } else {
                    this.$message.warning("请输入车牌号");
                }
            } else {
                this.$emit('login')
            }
        },
        goTruckMap() {
            this.$router.push({
                name: "TruckSubscribe",
                params: {
                    blno: this.rowData.blno,
                    boxPosition: this.rowData.boxPosition
                }
            });
        },
        showCarPosition(list) {
            // this.$alert('集卡定位功能维护中，给您的使用带来的不便敬请谅解，祝您生活愉快！', '提示', {
            //     confirmButtonText: '我知道了',
            //     type: 'warning'
            // })
            if (list["departureTime"]) {
                var dischargetime = new Date(list["departureTime"]).getTime();
                var nowtime = new Date().getTime();
                if (nowtime > dischargetime) {
                    this.$message.warning("已经离港不支持货车定位");
                    return;
                }
            }
            this.goTruckMap();
        },
    }
}
</script>

<style lang="stylus">
.box_position {
  display: flex;
  justify-content: center;
  align-items: center;
  .car-search {
        width: 20px;
        height: 20px;
        margin-left: 5px;
        cursor: pointer;
        background-image: url('../../../assets/img/position.png');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        user-select: none;
        transition: all 250ms ease-out;
        &:hover{
            transform: scale(1.1);
        }
    }
}

.el-dropdown-car {
  cursor: pointer;
  color: link-text-color-primary;
}

.el-dropdown-car__item {
  list-style: none;
  line-height: 36px;
  padding: 0 20px;
  margin: 0;
  font-size: 14px;
  color: #030303;
  cursor: text !important;
  outline: 0;
  align-items:center;
}

.el-dropdown-car__item:not(.is-disabled):focus {
  background-color: #fff;
  color: #030303;
}

.el-dropdown-car__item:not(.is-disabled):hover {
  background-color: #fff;
  color: #030303;
}

.delete-btn {
  width: 40px;
  cursor: pointer;
  color: rgb(213, 13, 0);
  user-select: none;
  margin: 0;
}

.table_link_text_bold {
    color: #409eff;
  font-weight:bold;
  font-size:14px;
  cursor: pointer;
  user-select: none;
  &:hover{
    opacity: 0.8;
  }
}

.subscribe-tooltip-wrap{
    font-size:12px;
    padding: 0 0 8px 16px;
    box-sizing: border-box;
    span{
        color: #F56C6C;
        font-weight:bold;
    }
}
</style>
